/* eslint-disable prettier/prettier */
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import MockupIphoneVidLightBg from "../videos/mock_iphone_light_bg.mp4";
import MockupIphoneVidDarkBg from "../videos/mock_iphone_dark_bg.mp4";
import MockupMacVid from "../videos/mock_mac.mp4";
export const About = () => {
  return (
    <>
      <section id="about" class="ud-pt-[20px]">
        <div class="ud-container">
          <div
            class="
          
            ud-border-b ud-border-white ud-border-opacity-[.15] ud-pb-[100px]
      "
          >
            <div class="ud-mx-[-16px] ud-flex ud-flex-wrap ud-items-center">
              <div class="ud-w-full ud-px-4 lg:ud-w-1/2">
                <div
                  class="wow fadeInUp ud-mb-12 ud-max-w-[570px] lg:ud-mb-0"
                  data-wow-delay=".15s"
                >
                  <h2
                    class="
                ud-mb-6
                ud-text-3xl
                ud-font-bold ud-leading-tight
                ud-text-black
                dark:ud-text-white
                sm:ud-text-4xl
                sm:ud-leading-tight
                md:ud-text-[45px]
                md:ud-leading-tight
                lg:ud-text-4xl
                lg:ud-leading-tight
                xl:ud-text-[45px]
                xl:ud-leading-tight
              "
                  >
                    Zwoppa leadkort
                  </h2>
                  <p
                    class="
                ud-mb-11 ud-text-base ud-font-medium
                ud-leading-relaxed
                ud-text-body-color
                sm:ud-text-lg
                sm:ud-leading-relaxed
              "
                  >
                    Marknadsplatsen som gör det möjligt för dig att ta tillvara
                    på information och skapa värde av den – på riktigt. Genom
                    att <strong>zwoppa</strong> på Zwop hamnar informationen på
                    rätt plats och till ett rättvist pris.
                  </p>
                  <div class="ud-mx-[-12px] ud-flex ud-flex-wrap">
                    <div
                      class="
                  ud-w-full
                  ud-px-3
                  sm:ud-w-1/2
                  lg:ud-w-full
                  xl:ud-w-1/2
                "
                    >
                      <p
                        class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
                      >
                        <span
                          class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                        >
                          <svg
                            width="16"
                            height="13"
                            viewBox="0 0 16 13"
                            class="ud-fill-current"
                          >
                            <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
                          </svg>
                        </span>
                        Tryggt
                      </p>
                      <p
                        class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
                      >
                        <span
                          class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                        >
                          <svg
                            width="16"
                            height="13"
                            viewBox="0 0 16 13"
                            class="ud-fill-current"
                          >
                            <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
                          </svg>
                        </span>
                        Säkert
                      </p>
                      <p
                        class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
                      >
                        <span
                          class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                        >
                          <svg
                            width="16"
                            height="13"
                            viewBox="0 0 16 13"
                            class="ud-fill-current"
                          >
                            <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
                          </svg>
                        </span>
                        Enkelt
                      </p>
                      <p
                        class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
                      >
                        <span
                          class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                        >
                          <svg
                            width="16"
                            height="13"
                            viewBox="0 0 16 13"
                            class="ud-fill-current"
                          >
                            <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
                          </svg>
                        </span>
                        Tidsbesparande
                      </p>
                    </div>
                    <div
                      class="
                  ud-w-full
                  ud-px-3
                  sm:ud-w-1/2
                  lg:ud-w-full
                  xl:ud-w-1/2
                "
                    >
                      <p
                        class="
                    ud-mb-5
                    ud-flex
                    ud-items-center
                    ud-text-lg
                    ud-font-medium
                    ud-text-body-color
                  "
                      >
                        <span
                          class="
                      ud-mr-4
                      ud-flex
                      ud-h-[30px]
                      ud-w-[30px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-md
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                        >
                          <svg
                            width="16"
                            height="13"
                            viewBox="0 0 16 13"
                            class="ud-fill-current"
                          >
                            <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
                          </svg>
                        </span>
                        Lönsamt
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="ud-w-full ud-px-4 lg:ud-w-1/2">
                <div
                  class="wow fadeInUp ud-text-center lg:ud-text-right "
                  data-wow-delay=".2s"
                >
                  <video
                    height="100%"
                    preload="auto"
                    autoPlay
                    loop
                    muted
                    playsInline
                    class=" ud-mx-auto ud-hidden ud-max-w-full dark:ud-block lg:ud-mr-0"
                  >
                    <source src={MockupIphoneVidDarkBg} type="video/mp4" />
                    Zwop marketplace in mobile app
                  </video>
                  <video
                    height="100%"
                    preload="auto"
                    autoPlay
                    loop
                    muted
                    playsInline
                    class=" ud-mx-auto ud-max-w-full dark:ud-hidden lg:ud-mr-0"
                  >
                    <source src={MockupIphoneVidLightBg} type="video/mp4" />
                    Zwop marketplace in mobile app
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="ud-pt-[100px] ud-pb-[120px]">
        <div class="ud-container">
          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap">
            <div class="ud-w-full  ud-px-4">
              <div
                class="
                wow fadeInUp ud-mx-auto ud-mb-[100px]
                ud-max-w-[570px]
                ud-text-center
              "
                data-wow-delay=".1s"
              >
                <h2
                  class="
                  ud-mb-4
                  ud-text-3xl
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                "
                >
                  Så fungerar Zwop
                </h2>
                <p
                  class="
                  ud-text-base ud-leading-relaxed
                  ud-text-body-color
                  md:ud-text-lg
                  md:ud-leading-relaxed
                "
                >
                  Som registrerad medlem kan du både köpa och sälja information.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="ud-container">
          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap ud-items-center">
            <div
              class="ud-w-full ud-px-4
              sm:ud-w-full
              lg:ud-w-1/2 xl:ud-w-1/2"
            >
              <div class="wow fadeInUp " data-wow-delay=".2s">
                <div class="ud-mb-9">
                  <h3
                    class="
                    ud-mb-8 ud-text-2xl
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-3xl
                    lg:ud-text-3xl
                    xl:ud-text-3xl
                  "
                  >
                    Att <strong>sälja</strong> leads och affärsinformation
                  </h3>

                  <h4
                    class="
                    ud-mb-1 ud-text-lg
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-xl
                    lg:ud-text-lg
                    xl:ud-text-xl
                  "
                  >
                    Du får information
                  </h4>
                  <p
                    class="
                    ud-mb-4 ud-text-base
                    ud-font-medium
                    ud-leading-relaxed
                    ud-text-body-color
                    sm:ud-text-lg  sm:ud-leading-relaxed
                  "
                  >
                    Du får höra information som kan vara affärsnyttig för andra.
                  </p>
                  <h4
                    class="
                    ud-mb-1 ud-text-lg
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-xl
                    lg:ud-text-lg
                    xl:ud-text-xl
                  "
                  >
                    Du skapar ett leadkort
                  </h4>
                  <p
                    class="
                    ud-mb-4 ud-text-base
                    ud-font-medium
                    ud-leading-relaxed
                    ud-text-body-color
                    sm:ud-text-lg  sm:ud-leading-relaxed
                  "
                  >
                    Du skapar och publicerar ett leadkort på ett par minuter. Du
                    bestämmer vad du vill ha betalt och vi kan hjälpa dig sätta
                    ett rimligt pris.
                  </p>
                  <h4
                    class="
                    ud-mb-1 ud-text-lg
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-xl
                    lg:ud-text-lg
                    xl:ud-text-xl
                  "
                  >
                    Du cashar in
                  </h4>
                  <p
                    class="
                    ud-mb-4 ud-text-base
                    ud-font-medium
                    ud-leading-relaxed
                    ud-text-body-color
                    sm:ud-text-lg
                    sm:ud-leading-relaxed
                  "
                  >
                    En köpare köper ditt leadkort och du tjänar pengar.
                  </p>
                </div>
              </div>
            </div>
            <div
              class="ud-w-full ud-px-4
                  sm:ud-w-full
                  lg:ud-w-1/2 xl:ud-w-1/2"
            >
              <div class="wow fadeInUp " data-wow-delay=".3s">
                <div class="ud-mb-9">
                  <h3
                    class="
                    ud-mb-8 ud-text-2xl
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-3xl
                    lg:ud-text-3xl
                    xl:ud-text-3xl
                  "
                  >
                    Att <strong>köpa</strong> leads och affärsinformation
                  </h3>

                  <h4
                    class="
                    ud-mb-1 ud-text-lg
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-xl
                    lg:ud-text-lg
                    xl:ud-text-xl
                  "
                  >
                    Du letar nya affärer
                  </h4>
                  <p
                    class="
                    ud-mb-4 ud-text-base
                    ud-font-medium
                    ud-leading-relaxed
                    ud-text-body-color
                    sm:ud-text-lg  sm:ud-leading-relaxed
                  "
                  >
                    Du är på jakt efter nya kunder och möjligheter.
                  </p>
                  <h4
                    class="
                    ud-mb-1 ud-text-lg
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-xl
                    lg:ud-text-lg
                    xl:ud-text-xl
                  "
                  >
                    Du hittar leads
                  </h4>
                  <p
                    class="
                    ud-mb-4 ud-text-base
                    ud-font-medium
                    ud-leading-relaxed
                    ud-text-body-color
                    sm:ud-text-lg  sm:ud-leading-relaxed
                  "
                  >
                    Du filtrerar och hittar unika leadkort på Zwop, snabbt och
                    enkelt.
                  </p>
                  <h4
                    class="
                    ud-mb-1 ud-text-lg
                    ud-font-bold
                    ud-text-black
                    dark:ud-text-white
                    sm:ud-text-xl
                    lg:ud-text-lg
                    xl:ud-text-xl
                  "
                  >
                    Du köper leads
                  </h4>
                  <p
                    class="
                    ud-mb-4 ud-text-base
                    ud-font-medium
                    ud-leading-relaxed
                    ud-text-body-color
                    sm:ud-text-lg
                    sm:ud-leading-relaxed
                  "
                  >
                    Du köper intressanta leadkort med ett klick och gör unika
                    affärer tack vare Zwop.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
