import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
const Hero = () => {
  return (
    <div
      id="home"
      className=" 
        ud-relative ud-z-10 ud-pt-[120px] ud-pb-[110px]
        md:ud-pt-[150px] md:ud-pb-[120px]
        xl:ud-pt-[180px] xl:ud-pb-[160px]
        2xl:ud-pt-[210px] 2xl:ud-pb-[200px]
      "
    >
      <div className="ud-container">
        <div className="ud-mx-[-16px] ud-flex ud-flex-wrap">
          <div className="ud-w-full ud-px-4">
            <div
              className="wow fadeInUp ud-mx-auto ud-max-w-[570px] ud-text-center"
              data-wow-delay=".2s"
            >
              <h1
                className="
                  ud-mb-5
                  ud-text-3xl
                  ud-font-bold ud-leading-tight
                  ud-text-black
                  dark:ud-text-white
                  sm:ud-text-4xl
                  sm:ud-leading-tight
                  md:ud-text-5xl
                  md:ud-leading-tight
                "
              >
                Köp och sälj affärsinformation🚀
              </h1>
              <p
                className="
                  ud-mb-12 ud-text-lg
                  ud-font-medium
                  ud-leading-relaxed
                  ud-text-body-color
                  dark:ud-text-white
                  dark:ud-opacity-90 md:ud-text-xl
                  md:ud-leading-relaxed
                "
              >
                Den nya digitala marknadsplatsen för dig som vill tipsa eller få
                tips om nya potentiella affärsmöjligheter
              </p>
              <div className="ud-flex ud-items-center ud-justify-center">
                <Link
                  className="
                    ud-mx-2
                    ud-rounded-full
                    ud-bg-primary
                    ud-py-4
                    ud-px-8
                    ud-text-base
                    ud-font-semibold
                    ud-text-white
                    ud-transition
                    ud-duration-300
                    ud-ease-in-out
                    hover:ud-bg-opacity-80
                    "
                  to={`${process.env.APP_URL}/signup`}
                >
                  Skapa konto
                </Link>
                <AnchorLink
                  className="
                     
                  ud-mx-2
                  ud-rounded-full
                  ud-bg-black
                  ud-bg-opacity-10
                  ud-py-4
                  ud-px-8 ud-text-base ud-font-semibold
                  ud-text-black ud-transition
                  ud-duration-300
                  ud-ease-in-out
                  hover:ud-bg-opacity-20
                  dark:ud-bg-white
                  dark:ud-bg-opacity-10
                  dark:ud-text-white
                  dark:hover:ud-bg-opacity-20
                    "
                  to={"/#about"}
                  title="Läs mer"
                >
                  Läs mer
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Hero;
