import React, { useEffect } from "react";
import { Link } from "gatsby";
export const Pricing = () => {
  useEffect(() => {}, []);

  return (
    <>
      <section
        id="membership"
        class="ud-relative ud-z-10 ud-pt-[120px] ud-pb-20"
      >
        <div class="ud-container">
          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap">
            <div class="ud-w-full ud-px-4">
              <div
                class="
                wow fadeInUp ud-mx-auto ud-mb-[100px]
                ud-max-w-[655px]
                ud-text-center
              "
                data-wow-delay=".1s"
              >
                <h2
                  class="
                  ud-mb-4
                  ud-text-3xl
                  ud-font-bold ud-text-black
                  dark:ud-text-white
                  sm:ud-text-4xl
                  md:ud-text-[45px]
                "
                >
                  Freemium och Premium
                </h2>
                <p
                  class="
                  ud-mx-auto ud-max-w-[570px]
                  ud-text-base
                  ud-leading-relaxed
                  ud-text-body-color
                  md:ud-text-lg md:ud-leading-relaxed
                "
                >
                  Du kan ansulta dig till Zwop helt kostnadsfritt - men har
                  också valet att bli premium.
                </p>
              </div>
            </div>
          </div>

          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap">
            <div class="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/2">
              <div
                class="
                wow fadeInUp ud-relative
                ud-z-10
                ud-mb-10 ud-rounded-md ud-bg-white ud-px-8 ud-py-10
                ud-shadow-signUp
                dark:ud-bg-[#1D2144]
              "
                data-wow-delay=".15s"
              >
                <div class="ud-flex ud-items-center ud-justify-between">
                  <h3
                    class="
                    price ud-mb-2
                    ud-text-3xl
                    ud-font-bold ud-text-black
                    dark:ud-text-white
                  "
                  >
                    <span class="amount">0</span> kr
                    <span class="time ud-text-dark dark:ud-text-body-color">
                      /månad
                    </span>
                  </h3>
                  <h4 class="ud-mb-2 ud-text-xl ud-font-bold dark:ud-text-white">
                    Freemium
                  </h4>
                </div>
                <p class="ud-mb-7 ud-text-base ud-text-body-color">
                  Med Freemium får du tillgång till allt du behöver för att
                  kunna zwoppa leadkort
                </p>
                <div
                  class="
                  ud-mb-8 ud-border-b
                  ud-border-body-color
                  ud-border-opacity-10
                  ud-pb-8
                  dark:ud-border-white dark:ud-border-opacity-10
                "
                >
                  <Link
                    className="
                    ud-flex
                    ud-w-full
                    ud-items-center
                    ud-justify-center
                    ud-rounded-full
                    ud-bg-primary
                    ud-p-3
                    ud-text-base
                    ud-font-semibold
                    ud-text-white
                    ud-transition ud-duration-300
                    ud-ease-in-out hover:ud-bg-opacity-80 hover:ud-shadow-signUp
                    "
                    to={`${process.env.APP_URL}/signup`}
                  >
                    Skapa konto
                  </Link>
                </div>
                <div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      Obegränsad försäljning av lead
                    </p>
                  </div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      Obegränsade köp av leads
                    </p>
                  </div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      18% transaktionsfee
                    </p>
                  </div>
                </div>
                <div class="ud-absolute ud-bottom-0 ud-right-0 ud-z-[-1]">
                  <svg
                    width="179"
                    height="158"
                    viewBox="0 0 179 158"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M75.0002 63.256C115.229 82.3657 136.011 137.496 141.374 162.673C150.063 203.47 207.217 197.755 202.419 167.738C195.393 123.781 137.273 90.3579 75.0002 63.256Z"
                      fill="url(#paint0_linear_70:153)"
                    />
                    <path
                      opacity="0.3"
                      d="M178.255 0.150879C129.388 56.5969 134.648 155.224 143.387 197.482C157.547 265.958 65.9705 295.709 53.1024 246.401C34.2588 174.197 100.939 83.7223 178.255 0.150879Z"
                      fill="url(#paint1_linear_70:153)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_70:153"
                        x1="69.6694"
                        y1="29.9033"
                        x2="196.108"
                        y2="83.2919"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#4A6CF7" stop-opacity="0.62" />
                        <stop
                          offset="1"
                          stop-color="#4A6CF7"
                          stop-opacity="0"
                        />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_70:153"
                        x1="165.348"
                        y1="-75.4466"
                        x2="-3.75136"
                        y2="103.645"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#4A6CF7" stop-opacity="0.62" />
                        <stop
                          offset="1"
                          stop-color="#4A6CF7"
                          stop-opacity="0"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div class="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/2">
              <div
                class="
                wow fadeInUp ud-relative
                ud-z-10
                ud-mb-10 ud-rounded-md ud-bg-white ud-px-8 ud-py-10
                ud-shadow-signUp
                dark:ud-bg-[#1D2144]
              "
                data-wow-delay=".2s"
              >
                <div class="ud-flex ud-items-center ud-justify-between">
                  <h3
                    class="
                    price ud-mb-2
                    ud-text-3xl
                    ud-font-bold ud-text-black
                    dark:ud-text-white
                  "
                  >
                    <span class="amount">99</span> kr
                    <span class="time ud-text-dark dark:ud-text-body-color">
                      /månad*
                    </span>
                  </h3>
                  <h4 class="ud-mb-2 ud-text-xl ud-font-bold dark:ud-text-white">
                    Premium
                  </h4>
                </div>
                <p class="ud-mb-7 ud-text-base ud-text-body-color">
                  Premium ger till tillgång till extra funktionalitet och
                  halverar din transaktionsfee när du zwoppar leadkort.
                </p>
                <div
                  class="
                  ud-mb-8 ud-border-b
                  ud-border-body-color
                  ud-border-opacity-10
                  ud-pb-8
                  dark:ud-border-white dark:ud-border-opacity-10
                "
                >
                  <Link
                    className="
                    ud-flex
                    ud-w-full
                    ud-items-center
                    ud-justify-center
                    ud-rounded-full
                    ud-bg-primary
                    ud-p-3
                    ud-text-base
                    ud-font-semibold
                    ud-text-white
                    ud-transition ud-duration-300
                    ud-ease-in-out hover:ud-bg-opacity-80 hover:ud-shadow-signUp
                    "
                    to={`${process.env.APP_URL}/signup`}
                  >
                    Skapa konto
                  </Link>
                </div>
                <div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      Obegränsad försäljning av leads
                    </p>
                  </div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      Obegränsade köp av leads
                    </p>
                  </div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      9% transaktionsfee
                    </p>
                  </div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      Dold profil
                    </p>
                  </div>
                  <div class="ud-mb-3 ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color
                    "
                    >
                      Export till CRM
                    </p>
                  </div>
                  <div class="ud-flex ud-items-center">
                    <span
                      class="
                      ud-mr-3
                      ud-flex
                      ud-h-[18px]
                      ud-w-full
                      ud-max-w-[18px]
                      ud-items-center
                      ud-justify-center
                      ud-rounded-full
                      ud-bg-primary
                      ud-bg-opacity-10
                      ud-text-primary
                    "
                    >
                      <svg
                        width="8"
                        height="6"
                        viewBox="0 0 8 6"
                        class="ud-fill-current"
                      >
                        <path d="M2.90567 6.00024C2.68031 6.00024 2.48715 5.92812 2.294 5.74764L0.169254 3.43784C-0.0560926 3.18523 -0.0560926 2.78827 0.169254 2.53566C0.39461 2.28298 0.74873 2.28298 0.974086 2.53566L2.90567 4.66497L7.02642 0.189715C7.25175 -0.062913 7.60585 -0.062913 7.83118 0.189715C8.0566 0.442354 8.0566 0.839355 7.83118 1.09198L3.54957 5.78375C3.32415 5.92812 3.09882 6.00024 2.90567 6.00024Z" />
                      </svg>
                    </span>
                    <p
                      class="
                      ud-m-0 ud-text-base ud-font-medium ud-text-body-color 
                    "
                    >
                      Gratis erbjudanden och kampanjer
                    </p>
                  </div>
                </div>
                <div
                  class="ud-mt-4 ud-border-t
                  ud-border-body-color
                  ud-border-opacity-10
                  ud-pb-8
                  ud-text-primary dark:ud-border-white  dark:ud-border-opacity-10"
                >
                  * Priset gäller för betaversion
                </div>
                <div class="ud-absolute ud-bottom-0 ud-right-0 ud-z-[-1]">
                  <svg
                    width="179"
                    height="158"
                    viewBox="0 0 179 158"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.5"
                      d="M75.0002 63.256C115.229 82.3657 136.011 137.496 141.374 162.673C150.063 203.47 207.217 197.755 202.419 167.738C195.393 123.781 137.273 90.3579 75.0002 63.256Z"
                      fill="url(#paint0_linear_70:153)"
                    />
                    <path
                      opacity="0.3"
                      d="M178.255 0.150879C129.388 56.5969 134.648 155.224 143.387 197.482C157.547 265.958 65.9705 295.709 53.1024 246.401C34.2588 174.197 100.939 83.7223 178.255 0.150879Z"
                      fill="url(#paint1_linear_70:153)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_70:153"
                        x1="69.6694"
                        y1="29.9033"
                        x2="196.108"
                        y2="83.2919"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#4A6CF7" stop-opacity="0.62" />
                        <stop
                          offset="1"
                          stop-color="#4A6CF7"
                          stop-opacity="0"
                        />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_70:153"
                        x1="165.348"
                        y1="-75.4466"
                        x2="-3.75136"
                        y2="103.645"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#4A6CF7" stop-opacity="0.62" />
                        <stop
                          offset="1"
                          stop-color="#4A6CF7"
                          stop-opacity="0"
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="ud-absolute ud-left-0 ud-bottom-0 ud-z-[-1]">
          <svg
            width="239"
            height="601"
            viewBox="0 0 239 601"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              opacity="0.3"
              x="-184.451"
              y="600.973"
              width="196"
              height="541.607"
              rx="2"
              transform="rotate(-128.7 -184.451 600.973)"
              fill="url(#paint0_linear_93:235)"
            />
            <rect
              opacity="0.3"
              x="-188.201"
              y="385.272"
              width="59.7544"
              height="541.607"
              rx="2"
              transform="rotate(-128.7 -188.201 385.272)"
              fill="url(#paint1_linear_93:235)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_93:235"
                x1="-90.1184"
                y1="420.414"
                x2="-90.1184"
                y2="1131.65"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#4A6CF7" />
                <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_93:235"
                x1="-159.441"
                y1="204.714"
                x2="-159.441"
                y2="915.952"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#4A6CF7" />
                <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </section>
    </>
  );
};

export default Pricing;
