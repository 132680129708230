/* eslint-disable prettier/prettier */
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
export const Brands = () => {
  return (
    <>
      <section class="ud-pt-16">
        <div class="ud-container">
          {" "}
          <h2 className=" ud-mb-[50px] ud-text-center ud-text-3xl ud-font-bold dark:ud-text-white sm:ud-text-4xl md:ud-text-[45px]">
            Media
          </h2>
          <div class="ud-mx-[-16px] ud-flex ud-flex-wrap">
            {" "}
            <div class="ud-mb-4 ud-w-full ud-px-4 ">
              <div
                class="
                wow
                fadeInUp ud-flex
                ud-w-full
                ud-flex-wrap
                ud-items-center
                ud-justify-center
                ud-rounded-md
                ud-bg-dark
                ud-py-8
                ud-px-8
                dark:ud-bg-primary
                dark:ud-bg-opacity-20
                sm:ud-px-10 
                md:ud-py-[40px]
                md:ud-px-[50px] xl:ud-p-[50px]
                2xl:ud-py-[60px]
                2xl:ud-px-[70px]
              "
                data-wow-delay=".1s
              "
              >
                <Link
                  className="
                      ud-mx-3 ud-flex ud-items-center
                      ud-justify-center
                      ud-py-[15px]
                      ud-opacity-70
                      ud-brightness-200
                      ud-grayscale
                      ud-transition
                      hover:ud-opacity-100
                      hover:ud-grayscale-0
                      dark:ud-opacity-60 dark:hover:ud-opacity-100
                      sm:ud-mx-4
                      lg:ud-max-w-[250px]
                      xl:ud-mx-6
                      xl:ud-max-w-[270px] 2xl:ud-mx-8
                      2xl:ud-max-w-[300px]
                    "
                  to={
                    "https://www.breakit.se/artikel/32943/de-vill-gora-det-mojligt-att-salja-och-kopa-affarsinformation-med-ny-marknadsplats"
                  }
                  target="_blank"
                >
                  <StaticImage
                    src="../images/breakit-logo-vector-cropped.svg"
                    alt="breakit logo"
                  />
                </Link>

                <Link
                  className="
                      ud-mx-3 ud-flex ud-items-center
                      ud-justify-center
                      ud-py-[15px]
                      ud-opacity-70
                      ud-brightness-200
                      ud-grayscale
                      ud-transition   
                      hover:ud-opacity-100
                      hover:ud-grayscale-0
                      dark:ud-opacity-60 dark:hover:ud-opacity-100
                      sm:ud-mx-4
                      lg:ud-max-w-[250px]
                      xl:ud-mx-6
                      xl:ud-max-w-[270px] 2xl:ud-mx-8
                      2xl:ud-max-w-[300px]
                    "
                  to={"https://it-retail.se/marknaden-dar-affarsinformation/"}
                  target="_blank"
                >
                  <StaticImage
                    src="../images/retail_se_.png"
                    alt="It-retail logo"
                    height={110}
                  />
                </Link>

                <Link
                  className="
                      ud-mx-3 ud-flex ud-items-center
                      ud-justify-center
                      ud-py-[15px]
                      ud-opacity-70
                      ud-brightness-200
                      ud-grayscale
                      ud-transition
                      hover:ud-opacity-100
                      hover:ud-grayscale-0
                      dark:ud-opacity-60 dark:hover:ud-opacity-100
                      sm:ud-mx-4
                      lg:ud-max-w-[250px]
                      xl:ud-mx-6
                      xl:ud-max-w-[270px] 2xl:ud-mx-8
                      2xl:ud-max-w-[300px]
                    "
                  to={
                    "https://branschaktuellt.se/hide-on-homepage/184011-it-miljonaren-startar-zwop/"
                  }
                  target="_blank"
                >
                  <StaticImage
                    src="../images/branschaktuellt-ny-logo-slogan-e1636727368879.png"
                    alt="Branschaktuellt logo"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Brands;
