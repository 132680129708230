import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const TestamonialCard = ({ testamonial }) => {
  return (
    <div class="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/3">
      <div
        class="
      wow fadeInUp
      ud-mb-10
      ud-rounded-md ud-bg-white
      ud-p-8
      ud-shadow-one
      dark:ud-bg-[#1D2144]
      lg:ud-px-5
      xl:ud-px-8
    "
        data-wow-delay=".1s"
      >
        <div class="ud-mb-5 ud-flex ud-items-center">
          {[...Array(testamonial.rating)].map((e, i) => (
            <span class="ud-mr-1 ud-block ud-text-yellow">
              <svg
                width="18"
                height="16"
                viewBox="0 0 18 16"
                class="ud-fill-current"
              >
                <path d="M9.09815 0.361679L11.1054 6.06601H17.601L12.3459 9.59149L14.3532 15.2958L9.09815 11.7703L3.84309 15.2958L5.85035 9.59149L0.595291 6.06601H7.0909L9.09815 0.361679Z" />
              </svg>
            </span>
          ))}
        </div>
        <p
          class="
        ud-mb-8 ud-border-b
        ud-border-body-color
        ud-border-opacity-10 ud-pb-8 ud-text-base ud-leading-relaxed
        ud-text-body-color
        dark:ud-border-white
        dark:ud-border-opacity-10
        dark:ud-text-white
      "
        >
          “ {testamonial.quote} “
        </p>
        <div class="ud-flex ud-items-center">
          <div
            class="
          ud-mr-4
          ud-h-[50px]
          ud-w-full
          ud-max-w-[50px]
          ud-overflow-hidden
          ud-rounded-full
        "
          >
            <GatsbyImage
              image={getImage(testamonial.avatar?.localFile)}
              alt={testamonial.avatar?.alternativeText}
              class="ud-w-full"
            />
          </div>
          <div class="ud-w-full">
            <h5
              class="
            ud-mb-1
            ud-text-lg
            ud-font-semibold
            ud-text-dark
            dark:ud-text-white
            lg:ud-text-base xl:ud-text-lg
          "
            >
              {testamonial.name}
            </h5>
            <p class="ud-text-sm ud-text-body-color">
              {testamonial.role}{" "}
              {testamonial.company ? `@${testamonial.company}` : ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestamonialCard;
