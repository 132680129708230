import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import ArticlesGrid from "../components/articles-grid";
import Hero from "../components/hero";
import Features from "../components/features";
import Brands from "../components/brands";
import Seo from "../components/seo";
import About from "../components/about";
import TestamonialGrid from "../components/testamonial-grid";
import Pricing from "../components/pricing";

const IndexPage = () => {
  const { strapiGlobal } = useStaticQuery(graphql`
    query {
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);

  return (
    <Layout>
      <Seo seo={{ metaTitle: "Köp och sälj affärsinformation" }} />

      <main>
        <Hero />
        <About />
        <TestamonialGrid />
        <Features />
        <Brands />
        <Pricing />
      </main>
    </Layout>
  );
};

export default IndexPage;
